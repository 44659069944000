import React, { useRef } from 'react';
import styled, { keyframes } from 'styled-components';

import img1 from '../../assets/char/1.svg';
import img2 from '../../assets/char/1.svg';
import img3 from '../../assets/char/1.svg';
import img4 from '../../assets/char/1.svg';
import img5 from '../../assets/char/1.svg';
import img6 from '../../assets/char/1.svg';
import img7 from '../../assets/char/1.svg';
import img8 from '../../assets/char/1.svg';
import img9 from '../../assets/char/1.svg';
import img10 from '../../assets/char/1.svg';
import ETH from '../../assets/icons8-ethereum-48.png';
import bgr from '../../assets/media/BGR3.png';

const Section = styled.section`
min-height: auto;
width: 100vw;
background-color: ${props => props.theme.body};
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
position: relative;
overflow: hidden;
background-image: url('https://i.postimg.cc/BvkhzyWt/Gallery-Backgound.png');
-webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;


  &>*:first-child{
    animation-duration: 20s;
  
    @media (max-width: 30em){
      animation-duration: 15s;
  
    }
  }
  &>*:last-child{
    animation-duration: 15s;
    @media (max-width: 30em){
      animation-duration: 10s;
  
    }
  }
`;
const move = keyframes`
0%{ transform: translateX(40%)   };
100%{ transform: translateX(-40%)   };

`;

const Row = styled.div`
/* background-color: lightblue; */
white-space: nowrap;
box-sizing:content-box;
margin: 2rem 0;
display: flex;

animation: ${move} linear infinite ${props => props.direction};


`;
const ImgContainer = styled.div`
width: 15rem;
margin: 0 1rem;
background-color:#242526;

border-radius: 0px;
cursor: pointer;

@media (max-width: 48em){
  width: 12rem;
  }
  @media (max-width: 30em){
  width: 10rem;
  }

img{
  width: 100%;
  height: auto;
}
`;

const Details = styled.div`
display: flex;
justify-content: space-between;
padding: 0.8rem 1rem;
background-color: ${props => props.theme.text};
border: 2px solid ${props => `rgba(${props.theme.bodyRgba},0.5)`};

border-bottom-left-radius: 0px;
border-bottom-right-radius: 0px;

span{
  font-size: ${props => props.theme.fontsm};
  color:${props => `rgba(${props.theme.bodyRgba},0.5)`};
  font-weight:600;
  line-height: 1.5rem;
}

h1{
  font-size: ${props => props.theme.fontmd};
  color: ${props => props.theme.body};
  font-weight:600;

  @media (max-width: 30em){
    font-size: ${props => props.theme.fontsm};

  }

}

`;

const Price = styled.div`
display: flex;
justify-content: flex-start;
align-items: center;

img{
  width: 1rem;
  height: auto;

}
`;

const NftItem = ({ img, number = 0, price = 0, passRef }) => {

  let play = (e) => {
    passRef.current.style.animationPlayState = 'running';
  };
  let pause = (e) => {
    passRef.current.style.animationPlayState = 'paused';
  };


  return (
    <ImgContainer onMouseOver={e => pause(e)} onMouseOut={e => play(e)}  >
      <img width={500} height={400} src={img} alt="The Weirdos" />

    </ImgContainer>
  );
};


const Showcase = () => {

  const Row1Ref = useRef(null);
  const Row2Ref = useRef(null);

  return (

    <Section id="showcase">
      <Row direction="none" ref={Row1Ref}>
        <NftItem img="https://i.postimg.cc/ncvpG5yT/image-1.png" number={852} price={'x'} passRef={Row1Ref} />
        <NftItem img='https://i.postimg.cc/VNhx8Wyd/image-10.png' number={123} price={'x'} passRef={Row1Ref} />
        <NftItem img='https://i.postimg.cc/sgjd929D/image-11.png' number={456} price={'x'} passRef={Row1Ref} />
        <NftItem img='https://i.postimg.cc/V6b3L014/image-12.png' number={666} price={'x'} passRef={Row1Ref} />
        <NftItem img=' https://i.postimg.cc/0yVgtqcG/image-13.png' number={452} price={'x'} passRef={Row1Ref} />
        <NftItem img='https://i.postimg.cc/rFWFYzr1/image-14.png' number={888} price={'x'} passRef={Row1Ref} />
        <NftItem img='https://i.postimg.cc/q7BJRLZw/image-15.png' number={211} price={'x'} passRef={Row1Ref} />
        <NftItem img='https://i.postimg.cc/wvZgwdx4/image-16.png' number={455} price={'x'} passRef={Row1Ref} />
        <NftItem img='https://i.postimg.cc/rpYkpL4y/image-2.png' number={456} price={'x'} passRef={Row1Ref} />
        <NftItem img='https://i.postimg.cc/76GyGQ13/image-3.png' number={865} price={'x'} passRef={Row1Ref} />
        <NftItem img='https://i.postimg.cc/kgsCkSXb/image-4.png' number={865} price={'x'} passRef={Row1Ref} />
        <NftItem img='https://i.postimg.cc/8cLQwJ0s/image-5.png' number={865} price={'x'} passRef={Row1Ref} />
        <NftItem img='https://i.postimg.cc/gkPPjsxh/image-6.png' number={865} price={'x'} passRef={Row1Ref} />
        <NftItem img='https://i.postimg.cc/ZKFzdNNM/image-7.png' number={865} price={'x'} passRef={Row1Ref} />
        <NftItem img='https://i.postimg.cc/xjm29h1Y/image-8.png' number={865} price={'x'} passRef={Row1Ref} />
        <NftItem img='https://i.postimg.cc/PqJg9FRg/image-9.png' number={865} price={'x'} passRef={Row1Ref} />
        <NftItem img='https://i.postimg.cc/QM6XWT23/image-26.png' number={865} price={'x'} passRef={Row1Ref} />
        <NftItem img='https://i.postimg.cc/fRNT4Zc6/image-CS2.png' number={865} price={'x'} passRef={Row1Ref} />
        <NftItem img='https://i.postimg.cc/L5PR70n9/image-CS24.png' number={865} price={'x'} passRef={Row1Ref} />
        <NftItem img='https://i.postimg.cc/pLJVR7ky/image-CS4.png' number={865} price={'x'} passRef={Row1Ref} />
        <NftItem img='https://i.postimg.cc/NM5Q80tr/image-hbkhb.png' number={865} price={'x'} passRef={Row1Ref} />
        <NftItem img='https://i.postimg.cc/ryR8KzgB/image-HHHHH.png' number={865} price={'x'} passRef={Row1Ref} />
        <NftItem img='https://i.postimg.cc/zffN5fVP/image-jgvjughv.png' number={865} price={'x'} passRef={Row1Ref} />
        <NftItem img='https://i.postimg.cc/wjgHf2Hz/image-ll.png' number={865} price={'x'} passRef={Row1Ref} />
        <NftItem img='https://i.postimg.cc/NM3v17bW/image-sf.png' number={865} price={'x'} passRef={Row1Ref} />


      </Row>
    </Section>
  );
};

export default Showcase;