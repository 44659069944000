import React, { lazy, Suspense } from 'react'
import styled, { keyframes } from 'styled-components'
// import CoverVideo from '../CoverVideo'
// import TypeWriterText from '../TypeWriterText'
import RoundTextBlack from '../../assets/Rounded-Text-White.png';
import Loading from '../Loading';
import bgr from '../../assets/media/BGR4.png';

const CoverVideo = lazy(() => import('../CoverVideo'));
const TypeWriterText = lazy(() => import('../TypeWriterText'));

const Section = styled.section`
min-height: auto;
width: 100vw;
position: relative;
background-image: url(${bgr});
-webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
background-color: ${props => props.theme.body};
`

const Container = styled.div`
width: 100%;
min-height: auto;


/* background-color: lightblue; */

display: flex;
justify-content: left;
align-items: left;

@media (max-width: 64em) {
  width: 100%;

 
}
@media (max-width: 48em) {
  img{
    width:20%;
  }
  flex-direction: column-reverse;
  width: 100%;
  &>*:first-child{
    width: 100%;
  }
}
`
const Box = styled.div`
width: 100%;
height: 100%;
display: flex;
flex-direction: column;
justify-content: left;
align-items: left;
`
const rotate = keyframes`
100%{
  transform: rotate(1turn);
}
`
const Round = styled.div`
position: absolute;
bottom: 2rem;
right: 90%;
width: 6rem;
height: 6rem;
border: 1px solid ${props => props.theme.text};
border-radius: 50%;

img{
  width: 100%;
  height: auto;
  animation: ${rotate} 6s linear infinite reverse ;
}
@media (max-width: 64em) {
  width: 4rem;
  height: 4rem;
  left: none;
  right: 2rem;
  bottom: 100%;
}
@media (max-width: 48em) {
  
  right: 1rem;
}
`



const Circle = styled.span`
width: 3rem;
height: 3rem;
display: flex;
justify-content: center;
align-items: center;
border-radius: 50%;

position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%,-50%);

background-color: ${props => props.theme.text};
color: ${props => props.theme.body};
font-size:${props => props.theme.fontxl};

@media (max-width: 64em) {
  width: 2rem;
  height: 2rem;
font-size:${props => props.theme.fontlg};

}

`

const Home = () => {
  return (
    <Section id="home">
      <Container>
      <img style={{width:'100%'}} src='https://i.postimg.cc/90B0VMmR/FINAL-BANNER.png'></img>

        
      </Container>
    </Section>
  )
}

export default Home